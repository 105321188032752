import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { useLogoutUserMutation } from "../../api/api";
import "./AccountNav.css";

const AccountNav = () => {
  const user = useSelector((state) => state.user);
  const [isExpanded, setIsExpanded] = useState(false);
  const [logoutUser] = useLogoutUserMutation();

  const handleLogout = async (e) => {
    e.preventDefault();
    if (user) {
      await logoutUser(user);
      window.location.replace("/");
    }
  };

  return (
    <div className="app-nav-container">
      <div className={isExpanded ? "app-nav-menu expanded" : "app-nav-menu"}>
        <ul>
          <li>
            <Link to="/lobby">
              <button className="brand-button-main">Lobby</button>
            </Link>
          </li>
          <li>
            <div className="dropdown">
              <div className="brand-button-main dropdown-button">Account</div>
              <div className="dropdown-menu">
                <Link to="/account">
                  <div className="dropdown-item">Home</div>
                </Link>
                <Link to="/account/settings">
                  <div className="dropdown-item">Settings</div>
                </Link>
                <div className="dropdown-item logout" onClick={handleLogout}>
                  Logout
                </div>
              </div>
            </div>
          </li>
        </ul>
        <i
          className={
            isExpanded
              ? "brand-color fa-solid fa-x fa-xl hamburger"
              : "brand-color fa-solid fa-bars fa-xl hamburger"
          }
          onClick={() => setIsExpanded(!isExpanded)}
        ></i>
      </div>
    </div>
  );
};

export default AccountNav;
