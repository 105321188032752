import React from "react";
import Navbar from "../components/Navbar";
import pixelDino from "../../assets/pixelDino.png";

const PageNotFound = () => {
  return (
    <div className="container">
      <Navbar />
      <div>
        <img className="dino-img" src={pixelDino} alt="pixel dinosuar" />
        <h1 className="white-text">Page Not Found!</h1>
      </div>
    </div>
  );
};


export default PageNotFound;