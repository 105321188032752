import React, { useState } from "react";
import { useNavigate, Link } from "react-router-dom";

import Navbar from "../../components/Navbar";
import { useLoginUserMutation } from "../../../api/api";

import "./AuthForm.css";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [alertMessage, setAlertMessage] = useState("");
  const navigate = useNavigate();

  const [loginUser] = useLoginUserMutation();

  const handleLogin = (e) => {
    e.preventDefault();
    if (email !== "" || password !== "") {
      loginUser({ email, password }).then(({ data }) => {
        if (data) {
          navigate("/account");
        } else {
          setAlertMessage("Incorrect email or password.");
        }
      });
    } else {
      setAlertMessage("Please enter your email or password.");
    }
  };

  return (
    <div className="container">
      <Navbar />
      <div className="form-container">
        <form className="form-field" onSubmit={handleLogin}>
          <div>
            <label className="white-text form-input-label">Email</label>
            <input
              className="form-input-bar"
              required
              type="text"
              placeholder="Enter Email"
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
              }}
            />
          </div>
          <div>
            <label className="white-text form-input-label">Password</label>
            <input
              className="form-input-bar"
              required
              type="password"
              placeholder="Password"
              value={password}
              onChange={(e) => {
                setPassword(e.target.value);
              }}
            />
          </div>
          <button className="submit-button">Login</button>
          <Link to ="/forgot-password">
          <div className="brand-text">Forgot password</div>
          </Link>
        </form>
      </div>
      <div className="alert">{alertMessage}</div>
      <div className="white-text">
        Don't have an account?{" "}
        <Link to="/register">
          <span>Register Here</span>
        </Link>
        .
      </div>
    </div>
  );
};

export default Login;
