import React, { useState, useContext } from "react";
import { Link } from "react-router-dom";

import { AppContext } from "../../context/appContext";
import GameSocket from "../components/GameSocket";

import "./GameNav.css";

const GameNav = (props) => {
  const [showingSettings, setShowingSettings] = useState(false);
  const { leavingGame, endingGame, resetGame, addingGame } = GameSocket();

  const { gameID, setGameID, player, players, noSleep, setSelectedPlayer } =
    useContext(AppContext);

  const handleRandomPlayer = () => {
    props.toggle();
    var player = players[Math.floor(Math.random() * players.length)];
    setSelectedPlayer(player.username);
  };

  const handleLeavingGame = () => {
    setGameID("");
    noSleep.disable();
    console.log("user:", props.user)
    addingGame(props.user, gameID);
    if (players.length === 1) {
      endingGame(player);
    } else {
      leavingGame(player, gameID);
    }
  };

  const handleResetGame = (e) => {
    e.preventDefault();
    resetGame(player, gameID);
  };

  return (
    <div className="app-nav-container">
      <div className="game-nav-menu">
        <ul>
          <li>
            <Link to="/account">
              <button className="brand-button-main" onClick={handleLeavingGame}>
                Leave
              </button>
            </Link>
          </li>
        </ul>
        <div className="game-menu">
          <i
            className="fa-solid fa-xl fa-gear brand-color game-settings"
            onClick={() => setShowingSettings(!showingSettings)}
          ></i>
          <div
            className={
              showingSettings
                ? "game-settings-menu expanded"
                : "game-settings-menu"
            }
          >
            <ul>
              <li>
                <button className="brand-button-main" onClick={handleResetGame}>
                  Reset
                </button>
              </li>
              <li>
                <button
                  className="brand-button-main"
                  onClick={handleRandomPlayer}
                >
                  Random
                </button>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GameNav;
