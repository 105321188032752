import React, { useContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import { AppContext } from "../../context/appContext";
import PlayerCard from "../components/PlayerCard";
import UserCard from "../components/UserCard";
import GameModal from "../components/GameModal";
import GameNav from "../components/GameNav";

import "./GameRoom.css";




const GameRoom = () => {
  const user = useSelector((state) => state.user);
  const { gameID, players, player, selectedPlayer, noSleep } = useContext(AppContext);

  const [showingModal, setShowingModal] = useState(false);

  const navigate = useNavigate();

  const handleShowModal = () => {
    setShowingModal(!showingModal);
    
  };

  useEffect(() => {
    noSleep.enable();
    if (gameID !== "") {
      window.localStorage.setItem("gameID", gameID);
      window.localStorage.setItem("player", JSON.stringify(player));
    }

    if (gameID === "") {
      navigate("/games/rejoin");
    }
  }, []);

  return (
    <div className="container">
      <GameNav toggle={handleShowModal} user={user}/>   
      <div className="lobby-code">{`Nexus: ${gameID}`}</div>
      <div className="game-container">
        <div className="user-grid">
          {players
            .filter((player) => player.username === user.username)
            .map((player) => (
              <UserCard
                key={player.username}
                username={player.username}
                health={player.health}
                profileAvatar={player.profileAvatar.source}
                commanderDamage={player.commanderDamage}
                poisonCount={player.poisonCount}
                player={player}
              />
            ))}
        </div>
        <div className="white-text player-grid">
          {players
            .filter((player) => player.username !== user.username)
            .map((player) => (
              <PlayerCard
                key={player.username}
                username={player.username}
                profileAvatar={player.profileAvatar}
                health={player.health}
                commanderDamage={player.commanderDamage}
                poisonCount={player.poisonCount}
                player={player}
              />
            ))}
        </div>
        {showingModal ? (
          <GameModal toggle={handleShowModal} text={selectedPlayer} />
        ) : null}
      </div>
    </div>
  );
};

export default GameRoom;
