export const FetchGames = async () => {
  let games = [];
  // await fetch("http://localhost:3000/games")
    await fetch("https://nexus-mtg-production.up.railway.app/games")
    .then((response) => response.json())
    .then((data) => {
      games = data;
    })
    .catch((error) => {
      return error;
    });
  return games;
};

export const FetchUser = async (user) => {
  // await fetch(`http://localhost:3000/users/${user}`)
    await fetch(`https://nexus-mtg-production.up.railway.app/users${user}`)
    .then((response) => response.json())
    .then((data) => {
      return data
    })
    .catch((error) => {
      return error;
    });
};
