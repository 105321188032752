import pfp1 from "./cactusAvatar.png";
import pfp2 from "./toiletPaperAvatar.png";
import pfp3 from "./icepopAvatar.png";
import pfp4 from "./coffeeAvatar.png";
import pfp5 from "./galaxyCatAvatar.png";
import pfp6 from "./foxAvatar.png";

import proPfp1 from "./reaperAvatar.png"
import proPfp2 from "./woodEyeAvatar.png"
import proPfp3 from "./potionAvatar.png"
import proPfp4 from "./halloweenOctoAvatar.png"

export const avatars = [
  {
    name: "Cactus",
    source: pfp1,
    iOSSource: "cactusAvatar"
  },
  {
    name: "Toilet Paper",
    source: pfp2,
    iOSSource: "toiletPaperAvatar"
  },
  {
    name: "Icepop",
    source: pfp3,
    iOSSource: "icepopAvatar"
  },
  {
    name: "Coffee",
    source: pfp4,
    iOSSource: "coffeeAvatar"
  },
  {
    name: "Galaxy Cat",
    source: pfp5,
    iOSSource: "galaxyCatAvatar"
  },
  {
    name: "Fox",
    source: pfp6,
    iOSSource: "foxAvatar"
  },
];

export const proAvatars = [
  {
    name: "Reaper",
    source: proPfp1,
    iOSSource: "reaperAvatar"
  },
  {
    name: "WoodEye",
    source: proPfp2,
    iOSSource: "woodEyeAvatar"
  },
  {
    name: "Potion",
    source: proPfp3,
    iOSSource: "potionAvatar"
  },
  {
    name: "HalloweenOcto",
    source: proPfp4,
    iOSSource: "halloweenOctoAvatar"
  },
]
