import React, { useState } from "react";
import { useSelector } from "react-redux";

import AccountNav from "../components/AccountNav";
import Friend from "../components/Friend";
import FriendModal from "../components/FriendModal";
import GameHistoryCard from "../components/GameHistoryCard";
import { useAddFriendMutation } from "../../api/api";
import { userSlice } from "../../api/userSlice";
import store from "../../api/store";

import "./Account.css";

const AccountHome = () => {
  const user = useSelector((state) => state.user);

  const [addFriend] = useAddFriendMutation();

  const [recentGamesExpanded, setRecentGamesExpanded] = useState(true);
  const [gameHistoryExpanded, setGameHistoryExpanded] = useState(false);
  const [friendsExpanded, setFriendsExpanded] = useState(true);

  const [showingModal, setShowingModal] = useState(false);
  const [username, setUsername] = useState("");
  const [alertMessage, setAlertMessage] = useState("");

  const toggleModal = () => {
    setAlertMessage("");
    setShowingModal(!showingModal);
  };

  const handleAddFriend = (e) => {
    e.preventDefault();
    if (username === "") {
      return setAlertMessage("Please enter a friend's username");
    }
    // const friend =
    addFriend({ user, username }).then((data) => {
      if (data.data) {
        console.log(data);
        setAlertMessage("");
        toggleModal();
        store.dispatch(userSlice.actions.setFriends([...user.friends, data.data]));
      } else if (data.error) {
        setAlertMessage("User not found.");
      }
    });
    setUsername("");
  };

  return (
    <div className="container">
      <AccountNav />
      <div className="account-container">
        <div className="profile-info">
          <img
            className="profile-pic"
            src={user.profileAvatar.source}
            alt="profile avatar"
          />
          <h3 className="username">{user.username}</h3>
        </div>
        <div className="account-content white-text">
          <div
            className={
              recentGamesExpanded ? "recent-games expanded" : "recent-games"
            }
          >
            <div className="row">
              <h2>Recent Games</h2>
              <i
                className="white-text fa-solid fa-chevron-up"
                onClick={() => setRecentGamesExpanded(!recentGamesExpanded)}
                style={{
                  transform: recentGamesExpanded ? "rotate(180deg)" : "",
                  transition: "transform 150ms ease",
                }}
              ></i>
            </div>
            <ul>
              {user.gameHistory.length === 0 ? (
                <li>No Recent Games</li>
              ) : (
                user.gameHistory.slice(-3).map((game) => (
                  <li>
                    <GameHistoryCard
                      key={game.gameID}
                      gameID={game.gameID}
                      createdAt={game.createdAt}
                    />
                  </li>
                ))
              )}
            </ul>
            <div className="game-history">
              <div className="row">
                <h2>Game History</h2>
                <i
                  className="white-text fa-solid fa-chevron-up"
                  onClick={() => setGameHistoryExpanded(!gameHistoryExpanded)}
                  style={{
                    transform: gameHistoryExpanded ? "rotate(180deg)" : "",
                    transition: "transform 150ms ease",
                  }}
                ></i>
              </div>
              <div>pro users get unlimited history</div>
            </div>
          </div>
          <div
            className={
              friendsExpanded ? "friends-list expanded" : "friends-list"
            }
          >
            {/* <div className="row">
              <h2>Friends</h2>
              <i
                className="white-text fa-solid fa-chevron-up"
                onClick={() => setFriendsExpanded(!friendsExpanded)}
                style={{
                  transform: friendsExpanded ? "rotate(180deg)" : "",
                  transition: "transform 150ms ease",
                }}
              ></i>
            </div>

            <ul>
              {user.friends.map((friend) => (
                <li>
                  <Friend
                    key={friend.username}
                    profileAvatar={friend.profileAvatar.source}
                    status={friend.status}
                    showingModal={showingModal}
                    toggle={toggleModal}
                    user={user}
                    username={friend.username}
                  />
                </li>
              ))}
              <button className="brand-button-main" onClick={toggleModal}>
                Add Friend
              </button>
            </ul> */}
          </div>

          {/* <h3> payment here</h3>
              <ul>
                <li>change theme</li>
                <li>pro avatars</li>
              </ul> */}
        </div>
        {showingModal ? (
          <FriendModal
            action={handleAddFriend}
            toggle={toggleModal}
            buttonLabel={"Add Friend"}
            label={"Adding Friend"}
            adding={true}
            setUsername={setUsername}
            username={username}
            alertMessage={alertMessage}
          />
        ) : null}
      </div>
    </div>
  );
};

export default AccountHome;
