import React from "react";

import "./Modal.css";

const FriendModal = (props) => {

  return (
    <div className="modal">
      <div className="modal-content">
        <span className="close" onClick={props.toggle}>
          &times;
        </span>
        <div className="white-text">{props.label}</div>
        {props.adding ? (
          <form className="form-field">
            <input
              className="form-input-bar"
              required
              type="text"
              placeholder="Friend's Username"
              value={props.username}
              onChange={(e) => {
                props.setUsername(e.target.value);
              }}
            />
          </form>
        ) : (
          <div className="modal-username white-text">{props.username}</div>
        )}
        <button className="brand-button-main" onClick={props.action}>
          {props.buttonLabel}
        </button>
        <div className="alert">{props.alertMessage}</div>
      </div>
    </div>
  );
};

export default FriendModal;
