import React from "react";
import "./Modal.css";

const GameModal = (props) => {
  return (
    <div className="modal">
      <div className="modal-content">
        <span className="close" onClick={props.toggle}>
          &times;
        </span>
        <div className="white-text">Player selected: {props.text}</div>
      </div>
    </div>
  );
};

export default GameModal;
