import React from "react";

import "./Feature.css";

const Feature = (props) => {
  return (
    <div >
      <div className="feature-highlight white-font">
        {props.className === "left-feature-item" ? (
          <div className={props.className}>
            <div>
              <div className="feature-title white-text">{props.feature}</div>
              <img
                className="feature-image"
                src={props.image}
                alt="placeholder"
              ></img>
            </div>
            <div className="feature-description white-text">{props.description}</div>
          </div>
        ) : (
          <div className={props.className}>
             <div className="feature-description white-text">{props.description}</div>
            <div>
              <div className="feature-title white-text">{props.feature}</div>
              <img
                className="feature-image"
                src={props.image}
                alt="placeholder"
              ></img>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Feature;
