import React, { useLayoutEffect } from "react";

import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import "./NexusProInfo.css";

const PrivacyPolicy = () => {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  });

  return (
    <div className="container">
      <Navbar />
      <div className="pro-container white-text">
        <div className="privacy-policy">
          <h1 className="faq-heading brand-color" >Privacy Policy</h1>

          <h2>Effective Date: July 1st, 2023</h2>

          <p>
            This Privacy Policy ("Policy") outlines the data collection, usage,
            and protection practices for Nexus: MTG Companion "App" operated by
            Nexus: MTG Companion ("Company" or "We" or "Us"). By using the App,
            you agree to the terms and conditions of this Policy.
          </p>

          <h3>1. Data Collection and Usage</h3>

          <h4>1.1 User Authentication and Verification</h4>

          <p>
            When you create an account on the App, we collect and store the
            following information:
          </p>

          <ul>
            <li>
              Email and Password: We require your email and password to
              authenticate and verify your identity. This information is used
              solely for the purpose of granting you access to the App's
              features and ensuring the security of your account.
            </li>
          </ul>

          <h4>1.2 User Identification during Gameplay</h4>

          <p>
            To enhance your gameplay experience, we may collect and store your
            username or user ID. This information helps us identify and
            differentiate users during gameplay content.
          </p>

          <h3>2. Data Sharing</h3>

          <h4>2.1 Third-Party Disclosure</h4>

          <p>
            We do not sell, trade, or transfer your personal data, including
            your email, password, username, or user ID, to third parties for any
            purpose, including advertising.
          </p>

          <h4>2.2 Service Providers</h4>

          <p>
            We may engage trusted third-party service providers who assist us in
            operating the App, improving its functionality, or providing
            customer support. These service providers have access to the data
            only to the extent necessary to perform their tasks on our behalf
            and are obligated to maintain the confidentiality and security of
            the information.
          </p>

          <h4>2.3 Legal Requirements</h4>

          <p>
            We may disclose your personal data if required by law or in response
            to valid requests from public authorities (e.g., court orders,
            government agencies) to comply with legal obligations, protect our
            rights or the safety of others, investigate fraud, or respond to a
            government request.
          </p>

          <h3>3. Data Deletion</h3>

          <p>
            If you choose to delete your account, we will permanently remove all
            associated data, including your email, password, username, and user
            ID. Please note that this process is irreversible, and you will not
            be able to recover your account or any related information after
            deletion.
          </p>

          <h3>4. Data Security</h3>

          <p>
            We implement industry-standard security measures to protect your
            personal data from unauthorized access, alteration, disclosure, or
            destruction. However, no method of transmission over the internet or
            electronic storage is 100% secure. While we strive to use
            commercially acceptable means to protect your information, we cannot
            guarantee absolute security.
          </p>

          <h3>5. Children's Privacy</h3>

          <p>
            The App is not intended for use by individuals under the age of 13.
            We do not knowingly collect personal information from children under
            13 years of age. If you believe that we have inadvertently collected
            information from a child under 13, please contact us immediately,
            and we will take steps to delete such information.
          </p>

          <h3>6. Changes to this Privacy Policy</h3>

          <p>
            We reserve the right to update or modify this Policy at any time.
            Any changes will be effective immediately upon posting the revised
            Policy on the App. It is your responsibility to review this Policy
            periodically for any updates or modifications. Your continued use of
            the App after the posting of changes constitutes your acceptance of
            such changes.
          </p>

          <h3>7. Contact Us</h3>

          <p>
            If you have any questions or concerns about this Privacy Policy or
            our data practices, please contact us at support@playnexus.app.
          </p>

          <p>
            By using the App, you acknowledge that you have read and understood
            this Privacy Policy and agree to be bound by its terms and
            conditions.
          </p>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default PrivacyPolicy;
