import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

import logo from "../../assets/NexusLogo.png"
import "./Navbar.css";

const Navbar = () => {
  const user = useSelector((state) => state.user);

// add logic to switch nav
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <div className="nav-container">
      <div className="logo-item">
        <Link to="/">
          <div className="row">
            <img className='nav-logo' src={logo} alt="nexus logo"/>
            <div className="logo-text brand-color">Nexus</div>
          </div>
          
        </Link>
      </div>

      <div className={isExpanded ? "nav-menu expanded" : "nav-menu"}>
        <ul>
          {!user ? (
            <>
              <li>
                <Link to="/login">
                  <button className="brand-button-main">Login</button>
                </Link>
              </li>
              <li>
                <Link to="/register">
                  <button className="brand-button-main">Join</button>
                </Link>
              </li>
            </>
          ) : (
            <Link to="/account">
              <button className="brand-button-main">Account</button>
            </Link>
          )}
        </ul>
        <i
          className={
            isExpanded
              ? "brand-color fa-solid fa-x fa-xl hamburger"
              : "brand-color fa-solid fa-bars fa-xl hamburger"
          }
          onClick={() => setIsExpanded(!isExpanded)}
        ></i>
      </div>
    </div>
  );
};

export default Navbar;
