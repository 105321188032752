const Counter = (props) => {
  switch (props.counterText) {
    case "HP":
      return props.health;
    case "Poison":
      return props.poisonCount;
    case "Commander Damage":
      return props.commanderDamage;
      case "Cmd Dmg":
      return props.commanderDamage;
    default:
      break;
  }
};

export default Counter;
