import React, { useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { useSelector } from "react-redux";
import NoSleep from "nosleep.js";
import "./App.css";

// Site Pages
import Home from "./site/pages/Home";
import FAQs from "./site/pages/FAQs";
import NexusProInfo from "./site/pages/NexusProInfo";
import PrivacyPolicy from "./site/pages/PrivacyPolicy";
import Login from "./site/pages/auth/Login";
import Register from "./site/pages/auth/Register";
import { AppContext } from "./context/appContext";


//Web App Pages
import AccountHome from "./webApp/pages/AccountHome";
import AccountSettings from "./webApp/pages/AccountSettings";
import GameRoom from "./webApp/pages/GameRoom";
import RejoinGame from './webApp/components/RejoinGame'
import PageNotFound from "./site/pages/PageNotFound";
import Lobby from "./webApp/pages/Lobby";
import ForgotPassword from "./site/pages/auth/ForgotPassword";
import ResetPassword from "./site/pages/auth/ResetPassword";
import EULA from "./site/pages/EULA";

function App() {
  const user = useSelector((state) => state.user);
  const noSleep = new NoSleep();

  const [gameID, setGameID] = useState('');
  const [players, setPlayers] = useState([]);
  const [player, setPlayer] = useState({})
  const [selectedPlayer, setSelectedPlayer] = useState("")

  return (
    <AppContext.Provider
      value={{
        gameID,
        setGameID,
        players,
        setPlayers,
        player,
        setPlayer,
        selectedPlayer,
        setSelectedPlayer,
        noSleep
      }}
    >
      <BrowserRouter>
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/faq" element={<FAQs />} />
          <Route exact path="/nexus-pro" element={<NexusProInfo />} />
          <Route exact path="/privacy" element={<PrivacyPolicy />} />
          <Route exact path="/terms" element={<EULA />} />
          <Route exact path="/login" element={<Login />} />
          <Route exact path="/register" element={<Register />} /> 
          <Route exact path="/forgot-password" element={<ForgotPassword />} />
          <Route exact path="/reset-password/:id/:token" element={<ResetPassword />} /> 
          <Route path="*" element={<PageNotFound />} />
          {user && (
            <>
              <Route exact path="/lobby" element={<Lobby />} />
              <Route exact path="/account" element={<AccountHome />} />
              <Route exact path="/account/settings" element={<AccountSettings />} />
              <Route exact path="/games/:gameID" element={<GameRoom />} />
              <Route exact path="/games/rejoin" element={<RejoinGame />} />
            </>
          )}
        </Routes>
      </BrowserRouter>
    </AppContext.Provider>
  );
}

export default App;
