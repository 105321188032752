import React, { useState, useContext } from "react";
import { AppContext } from "../../context/appContext";

import GameSetup from "./GameSetup";
import JoinGame from "./JoinGame";
import { CodeFilter } from "../Helpers/CodeFilter";
import { FetchGames } from "../Helpers/APIFetch";
import AccountNav from "../components/AccountNav";

import "./Lobby.css";

const Lobby = () => {
  const { setGameID } = useContext(AppContext);

  const [creatingGame, setCreatingGame] = useState(false);

  const gameCodeGenerator = (length) => {
    // const fetchedGames = FetchGames()
    // console.log(fetchedGames.results)
    let generatedCode = "";
    let counter = 0;
    const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890";
    const charactersLength = characters.length;

    while (counter < length) {
      generatedCode += characters.charAt(
        Math.floor(Math.random() * charactersLength)
      );
      counter++;
    }
    for (let i = 0; i < CodeFilter.length; i++) {
      if (generatedCode.includes(CodeFilter[i])) {
        generatedCode = "";
      } else {
        return generatedCode;
      }
    }
  };

  const handleCreateGame = (e) => {
    e.preventDefault();
    setGameID(gameCodeGenerator(5));
    setCreatingGame(true);
  };

  const handleCancelGame = () => {
    setGameID("");
    setCreatingGame(false);
  };

  return (
    <div className="container">
      <AccountNav />
      <div className="lobby-container">
        <div className="game-setup-container white-text">
          {creatingGame ? (
            <div>
              <GameSetup handleCancelGame={handleCancelGame} />
            </div>
          ) : (
            <div>
              <div className="header brand-color">NEXUS</div>
              <div className="">Create a new game below</div>
              <button className="submit-button" onClick={handleCreateGame}>
                Create a New Game
              </button>
              <div> or join an existing game </div>
              <JoinGame />
              <p className="helper-text">Once you join or create a game, turn your phone horizontal for a more optimal experience</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Lobby;
