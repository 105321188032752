import nexusProfile from "../../assets/mockups/nexusProfileExample.png";
import nexusLinkExample from "../../assets/mockups/nexusLinkExample.png";
import nexusGameModes from "../../assets/mockups/nexusGameModes.png";
import nexusDeckManager from "../../assets/mockups/nexusDeckManagerExample.png"
import nexusCardLookup from "../../assets/mockups/nexusCardLookupExample.png"

export const features = [
  {
    id: 1,
    feature: "Real-Time Gameplay",
    description:
      "Seamlessly connect with up to eight other players to manage your counters and keep track of everyone else's all in real-time.",
    image: nexusLinkExample,
    className: "left-feature-item",
  },
  {
    id: 2,
    feature: "Multiple Game Modes",
    description:
      "Nexus provides multiple game modes to let you play the way you want to. Nexus Link allows up to 9 players to connect in real-time. Prefer a normal counter app? Classic Mode has got you covered.",
    image: nexusGameModes,
    className: "right-feature-item",
  },
  {
    id: 3,
    feature: "Player Profiles",
    description:
      "Create and customize your own nexus profile. Keep track of your game history, decks and your identity to let other's know who you are in games.",
    image: nexusProfile,
    className: "left-feature-item",
  },
  {
    id: 4,
    feature: "Deck Manager",
    description:
      "Manage your decks and keep track of each one's win rate and which ones dominate your friends.",
    image: nexusDeckManager,
    className: "right-feature-item",
  },
  {
    id: 5,
    feature: "Card Lookup",
    description:
      "Lookup individual cards and see their details and their legality for each game type.",
    image: nexusCardLookup,
    className: "left-feature-item",
  },
];

export default features;
