import { configureStore } from "@reduxjs/toolkit";

import storage from "redux-persist/lib/storage";
import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import thunk from "redux-thunk";

import userSlice from "./userSlice";
// import gameSlice from "./gameSlice";
import api from "./api";

//reducers
const reducer = combineReducers({
  user: userSlice,
  [api.reducerPath]: api.reducer,
});

const persistConfig = {
  key: "root",
  storage,
  blacklist: [api.reducerPath],
};

// persist store
const persistedReducer = persistReducer(persistConfig, reducer);

//Create store
const store = configureStore({
  reducer: persistedReducer,
  middleware: [thunk, api.middleware],
});

export default store;
