import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { AppContext } from "../../context/appContext";
import GameSocket from "./GameSocket";

import "./RejoinGame.css";

const RejoinGame = () => {
  const { gameID, setGameID } = useContext(AppContext);

  const [returningPlayer, setReturningPlayer] = useState(null);
  const [boardWipe, setBoardWipe] = useState('Wrath of God')

  const navigate = useNavigate();
  const { rejoinGame, leavingGame } = GameSocket();

  const handleRejoinGame = async () => {
    console.log(returningPlayer)
    await rejoinGame(returningPlayer, gameID);
    navigate(`/games/${gameID}`);
  };

  const getBoardWipe = () => {
    const boardWipes = [
      "Wrath of God",
      "Toxic Deluge",
      "Damnation",
      "Blasphemous Act",
    ];
    const index = Math.floor(Math.random() * boardWipes.length);
    setBoardWipe(boardWipes[index])
  };

  useEffect(() => {
    getBoardWipe()
    const player = window.localStorage.getItem("player");
    setReturningPlayer(JSON.parse(player));
    
    setGameID(window.localStorage.getItem("gameID"));
    leavingGame(JSON.parse(player), window.localStorage.getItem("gameID"));
  }, []);

  return (
    <div className="container">
      <div className="center">
        <div className="white-text rejoin-prompt">
          Oh No! Did someone cast {boardWipe} causing you to get lost and leave your game? Don't worry, the
          button below will portal you back to your desired realm!
        </div>
        <button className="brand-button-main" onClick={handleRejoinGame}>
          rejoin
        </button>
      </div>
    </div>
  );
};

export default RejoinGame;
