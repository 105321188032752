import React, { useState } from "react";
import Avatar from "./Avatar";
import { avatars } from "../../../assets/Avatars/Avatars";

import "./ProfileCreation.css";

const ProfileCreation = (props) => {
  const [activeAvatar, setActiveAvatar] = useState();

  const handleToggle = (avatar) => {
    activeAvatar === avatar ? setActiveAvatar() : setActiveAvatar(avatar);
  };

  const handleSetAvatar = (avatar) => {
    if(props.profileAvatar === avatar) {
        props.setProfileAvatar();
    } else {
        props.setProfileAvatar(avatar);
    }
  };

  const previousPage = () => {
    props.setCreatingAccount(!props.creatingAcccount)
  }

  const register = (e) => {
    e.preventDefault();
    props.handleRegister();
  };

  return (
    <div className="container">
      <div className="white-text lobby-code">Create Your Profile:</div>
      <div className="form-container">
        <form className="form-field" onSubmit={register}>
          <div>
            <label className="white-text form-input-label">Username</label>
            <input
              className="form-input-bar"
              required
              type="text"
              placeholder="Enter Username"
              value={props.username}
              onChange={(e) => {
                props.setUsername(e.target.value);
              }}
            />
          </div>

          <div className="avatar-form-field">
            <span className="white-text">Select a profile avatar:</span>
            <div className="avatar-grid">
              {avatars.map((avatar) => (
                <div
                  key={avatar.name}
                  onClick={() => {
                    handleSetAvatar(avatar);
                  }}
                >
                  <Avatar
                    avatar={avatar}
                    handleToggle={handleToggle}
                    activeAvatar={activeAvatar === avatar}
                  />
                </div>
              ))}
            </div>
          </div>
          <button className="horizontal-button" onClick={previousPage}>Back</button>
          <button className="horizontal-button">Create Account</button>
        </form>
      </div>
      <div className="alert">{props.alertMessage}</div>
    </div>
  );
};

export default ProfileCreation;
