import { useContext, useEffect, useRef } from "react";
import { io } from "socket.io-client";

import {
  useCreateGameMutation,
  useJoinGameMutation,
  useLeaveGameMutation,
  useUpdatePlayerMutation,
  useEndGameMutation,
  useAddGameMutation,
} from "../../api/api";
import { AppContext } from "../../context/appContext";
import { Game } from "../Models/GameModel";
import { Player } from "../Models/PlayerModel";
import { FetchGames } from "../Helpers/APIFetch";
import { userSlice } from "../../api/userSlice";
import store from "../../api/store";
import { HistoricGame } from "../Models/HistoricGameModel";

// const SOCKET_URL = process.env.REACT_APP_SERVER_URL;
const SOCKET_URL = "https://nexus-mtg-production.up.railway.app";
// const SOCKET_URL = "http://localhost:3000";

const GameSocket = () => {
  const { gameID, players, setPlayers, setPlayer } = useContext(AppContext);

  const [joinGame] = useJoinGameMutation();
  const [leaveGame] = useLeaveGameMutation();
  const [createGame] = useCreateGameMutation();
  const [endGame] = useEndGameMutation();
  const [updatePlayer] = useUpdatePlayerMutation();
  const [addGame] = useAddGameMutation();

  const socket = useRef();
  const game = useRef();

  const fetchGame = async (gameID) => {
    // await fetch(`http://localhost:3000/games/${gameID}`)
      await fetch(`https://nexus-mtg-production.up.railway.app/games/${gameID}`)
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        const fetchedGame = new Game(
          data._id,
          data.gameID,
          data.allPlayers,
          data.players,
          data.startingHealth,
          data.createdAt,
          data.updatedAt,
          data.__v
        );
        game.current = fetchedGame;
        setPlayers(fetchedGame.players);
      });
  };

  useEffect(() => {
    // Create socket connection
    socket.current = io(SOCKET_URL, {
      query: { gameID: gameID },
      reconnection: true,
      reconnectionDelay: 500,
      reconnectionAttempts: 10,
    });

    const eventListener = (player) => {
      console.log(player);
      setPlayers([...players, player]);
    };

    socket.current.off("join-game").on("join-game", eventListener);

    socket.current.off("leave-game").on("leave-game", (player) => {
      setPlayers(players.filter((p) => p.username !== player.username));
    });

    socket.current.off("reset-player").on("reset-player", async (player) => {
      console.log(player);
      const resetPlayer = new Player(
        player.username,
        player.profileAvatar,
        player.health,
        player.commanderDamage,
        player.poisonCount
      );
      const resetPlayers = await players.map((p) => {
        if (p.username === player.username) {
          window.localStorage.setItem("player", JSON.stringify(resetPlayer));
          return resetPlayer;
        } else {
          return p;
        }
      });
      await setPlayers(resetPlayers);
    });

    socket.current
      .off("update-player")
      .on("update-player", async (player, unit, amount) => {
        console.log("updated player:", player);
        console.log("updated unit:", unit);
        console.log("updated amount:", amount);
        let updatedPlayer;
        switch (unit) {
          case "health":
            updatedPlayer = new Player(
              player.username,
              player.profileAvatar,
              amount,
              player.commanderDamage,
              player.poisonCount
            );
            break;
          case "poisonCount":
            updatedPlayer = new Player(
              player.username,
              player.profileAvatar,
              player.health,
              player.commanderDamage,
              amount
            );
            break;
          case "commanderDamage":
            updatedPlayer = new Player(
              player.username,
              player.profileAvatar,
              player.health,
              amount,
              player.poisonCount
            );
            break;
          default:
            break;
        }
        const updatedPlayers = await players.map((p) => {
          if (p.username === player.username) {
            return updatedPlayer;
          } else {
            return p;
          }
        });
        await setPlayers(updatedPlayers);
      });

    return () => {
      socket.current.off("game-started");
      socket.current.off("join-game");
      socket.current.off("leave-game");
      socket.current.off("update-player");
    };
  }, [setPlayers, gameID, players, setPlayer]);

  const creatingGame = async (player, startingHealth) => {
    await createGame({
      gameID: gameID,
      player: player,
      startingHealth: startingHealth,
    }).then(({ data }) => {
      if (data) {
        socket.current.emit("join-game", player);
      }
    });
  };

  const joiningGame = async (user) => {
    await fetchGame(gameID);
    const newPlayer = new Player(
      user.username,
      user.profileAvatar,
      game.current.startingHealth,
      0,
      0
    );

    await joinGame({ gameID: gameID, player: newPlayer }).then(({ data }) => {
      if (data) {
        socket.current.emit("join-game", newPlayer);
        setPlayer(newPlayer);
      }
    });
  };

  const rejoinGame = async (player, gameID) => {
    await fetchGame(gameID);
    console.log(player);
    await joinGame({ gameID: gameID, player: player }).then(({ data }) => {
      if (data) {
        socket.current.emit("join-game", player);
        setPlayer(player);
      }
    });
  };

  const resetGame = async (player, gameID) => {
    await fetchGame(gameID);
    const resetPlayer = new Player(
      player.username,
      player.profileAvatar,
      game.current.startingHealth,
      0,
      0
    );

    setPlayer(resetPlayer);
    await socket.current.emit("reset-player", resetPlayer);
  };

  const leavingGame = async (player, gameID) => {
    await leaveGame({ gameID: gameID, player: player }).then(({ data }) => {
      if (data) {
        socket.current.emit("leave-game", player);
        setPlayers([]);
      }
    });
  };

  const endingGame = async (player) => {
    setPlayers([]);
    await endGame({ gameID: gameID, player: player }).then(({ data }) => {
      if (data) {
        socket.current.emit("leave-game", gameID);
      }
    });
  };

  const addingGame = async (user, gameID) => {
    await fetchGame(gameID);
    const historicGame = new HistoricGame(
      "nexus",
      game.current.createdAt,
      game.current.gameID,
      game.current.allPlayers
    );
    await addGame({ user: user, game: historicGame }).then(({ data }) => {
      if (data) {
        console.log(data);
      }
      console.log(game.current);
      store.dispatch(
        userSlice.actions.setGames([...user.gameHistory, game.current])
      );
    });
  };

  const updatingPlayer = async (player, unit, amount) => {
    let updatedPlayer;
    switch (unit) {
      case "health":
        updatedPlayer = new Player(
          player.username,
          player.profileAvatar,
          amount,
          player.commanderDamage,
          player.poisonCount
        );
        break;
      case "poisonCount":
        updatedPlayer = new Player(
          player.username,
          player.profileAvatar,
          player.health,
          player.commanderDamage,
          amount
        );
        break;
      case "commanderDamage":
        updatedPlayer = new Player(
          player.username,
          player.profileAvatar,
          player.health,
          amount,
          player.poisonCount
        );
        break;
      default:
        break;
    }
    setPlayer(updatedPlayer);
    window.localStorage.setItem("player", JSON.stringify(updatedPlayer));
    await socket.current.emit("update-player", {
      player: player,
      unit: unit,
      amount: amount,
    });

    await updatePlayer({
      gameID: gameID,
      player: player,
      unit,
      newAmount: amount,
    });
  };

  return {
    creatingGame,
    joiningGame,
    rejoinGame,
    resetGame,
    leavingGame,
    updatingPlayer,
    endingGame,
    addingGame,
  };
};

export default GameSocket;
