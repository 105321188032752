import React, { useState, useEffect } from "react";
import "./GameHistoryCard.css";

const GameHistoryCard = (props) => {
  const [gameDate, setGameDate] = useState("");

  useEffect(() => {
    var d = props.createdAt.split(/\D+/);
    const date = `${d[1]}/${d[2]}/${d[0]}`;
    setGameDate(date);
  }, [props.createdAt]);

  return (
    <div className="game-card">
      <div className="game-card-title">
        NEXUS ID: <span className="brand-color bold-text"> {props.gameID}</span>
      </div>
      <div className="game-card-date">{gameDate}</div>
    </div>
  );
};

export default GameHistoryCard;
