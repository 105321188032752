import React from "react";
import "./Modal.css";

const DangerModal = (props) => {
  return (
    <div className="modal">
      <div className="modal-content">
        <span className="close" onClick={props.toggle}>
          &times;
        </span>
        <h2 className="modal-title white-text">Delete Account</h2>
        <p>
          Deleting your account is permament and there is no way to retrieve it
          back. All user data will be instantly deleted.
        </p>
        <p>Enter your email and password to delete your account.</p>
        <form className="form-field modal-form" onSubmit={props.action}>
          <div>
            <input
              className="form-input-bar"
              required
              type="text"
              placeholder="Enter Email"
              value={props.deleteVerifyEmail}
              onChange={(e) => {
                props.setDeleteVerifyEmail(e.target.value);
              }}
            />
          </div>
          <div>
            <input
              className="form-input-bar"
              required
              type="password"
              placeholder="Enter Password"
              value={props.deleteVerifyPassword}
              onChange={(e) => {
                props.setDeleteVerifyPassword(e.target.value);
              }}
            />
          </div>
          <div className="alert">{props.modalAlertMessage}</div>
          <button className="brand-button-main delete">
          Delete Account
        </button>
          </form>
          
      </div>
    </div>
  );
};

export default DangerModal;
