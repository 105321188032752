import { createSlice } from "@reduxjs/toolkit";
import api from "./api";

export const userSlice = createSlice({
  name: "user",
  initialState: null,
  reducers: {
    setUsername: (state, action) => {
      state.username = action.payload
    },
    setEmail: (state, action) => {
      state.email = action.payload
    },
    setAvatar: (state, action) => {
      state.profileAvatar = action.payload
    },
    setFriends: (state, action) => {
      state.friends = action.payload
    },
    setGames: (state, action) => {
      state.gameHistory = action.payload
    }

  },

  extraReducers: (builder) => {
    builder.addMatcher(
      api.endpoints.createUser.matchFulfilled,
      (state, { payload }) => payload
    );
    builder.addMatcher(
      api.endpoints.loginUser.matchFulfilled,
      (state, { payload }) => payload
    );
    builder.addMatcher(
      api.endpoints.logoutUser.matchFulfilled,
      (state) => null
    );
    builder.addMatcher(
      api.endpoints.deleteAccount.matchFulfilled,
      (state) => null
    );
    builder.addMatcher(
      api.endpoints.updateUserUsername.matchFulfilled,
      (state,  {payload}) => payload
    );
  },
});

export default userSlice.reducer;
