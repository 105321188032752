import React, { useState, useEffect } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";

import Navbar from "../../components/Navbar";
import { useResetPasswordMutation } from "../../../api/api";

const ResetPassword = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [alertMessage, setAlertMessage] = useState("");
  const [validToken, setValidToken] = useState(true);
  const [resetSuccessful, setResetSuccessful] = useState(false);

  const { id, token } = useParams();
  const navigate = useNavigate();

  const [resetPassword] = useResetPasswordMutation();

  const handleResetPassword = (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      return setAlertMessage("New passwords do not match.");
    } else if (password.length < 8) {
      return setAlertMessage(
        "New password must be at least 8 characters long."
      );
    } else if (!/\d/.test(password)) {
      return setAlertMessage(
        "Your new password must contain at least 1 numeric character."
      );
    } else {
      setAlertMessage(
        "Password successfully reset. If you're not automatically redirectly, you can select login or navigate back to the Nexus App."
      );
      setResetSuccessful(true);
      resetPassword({ password, id, token }).then((data) => {
        navigate("/login");
        if (data) {
          console.log(data);
        }
      });
    }
  };

  useEffect(() => {
    // fetch(`http://localhost:3000/reset-password/${id}/${token}`)
    fetch(
      `https://nexus-mtg-production.up.railway.app/reset-password/${id}/${token}`
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.validToken === true) {
          setEmail(data.email);
          setAlertMessage("");
        } else {
          setValidToken(false);
        }
      });
  }, []);

  return (
    <div className="container">
      <Navbar />
      {validToken ? (
        <div>
          <h1 className="white-text">Reset password for {email}</h1>
          <div className="form-container">
            <form className="form-field" onSubmit={handleResetPassword}>
              <div>
                <label className="white-text form-input-label">
                  New Password
                </label>
                <input
                  className="form-input-bar"
                  required
                  type="password"
                  placeholder="Enter New Password"
                  value={password}
                  onChange={(e) => {
                    setPassword(e.target.value);
                  }}
                />
              </div>
              <div>
                <label className="white-text form-input-label">
                  Confirm New Password
                </label>
                <input
                  className="form-input-bar"
                  required
                  type="password"
                  placeholder="Confirm New Password"
                  value={confirmPassword}
                  onChange={(e) => {
                    setConfirmPassword(e.target.value);
                  }}
                />
              </div>
              <button disabled={resetSuccessful} className="submit-button">
                Reset Password
              </button>
            </form>
          </div>
          <div className="alert">{alertMessage}</div>
        </div>
      ) : (
        <div>
          <h1 className="white-text">
            Reset password link has expired.{" "}
            <Link to="/forgot-password">
              <span className="brand-text">Try again here.</span>
            </Link>
          </h1>
        </div>
      )}
    </div>
  );
};

export default ResetPassword;
