import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import words from "profane-words"; 

import Navbar from "../../components/Navbar";
import AccountCreation from "./AccountCreation";
import ProfileCreation from "./ProfileCreation";
import { useCreateUserMutation } from "../../../api/api";
import "./AuthForm.css";

const Register = () => {
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("")
  const [profileAvatar, setProfileAvatar] = useState();
  const [alertMessage, setAlertMessage] = useState("");
  const [creatingAccount, setCreatingAccount] = useState(true);

  const navigate = useNavigate();

  const [createUser] = useCreateUserMutation();

  const handleNextStep = () => {
    setCreatingAccount(false);
  };

  const handleRegister = async () => {
    for (let i = 0; i < words.length; i++) {
      if (username.includes(words[i])) {
        return setAlertMessage("Please pick a more appropriate username.");
      } else if(!/^[a-zA-Z0-9]+$/.test(username)) {
        return setAlertMessage("Username can only contain alphanumeric characters.");
      } else if (!profileAvatar) {
        return setAlertMessage("Please select an avatar.");
      } else if (!username) {
        return setAlertMessage("");
      } else if (password !== confirmPassword) {
        return setAlertMessage("Passwords do not match.")
      }
    }

    setAlertMessage("")
    createUser({ username, email, password, profileAvatar }).then(
      ({ data }) => {
        if (data) {
          console.log(data);
          setAlertMessage("")
        } else {
          console.log("no data")
          setAlertMessage("Email or username already taken.")
        }

        if (data.success === false) {
          return setAlertMessage(data.error.message)
        }
        navigate("/account");
      }
    );
  };

  return (
    <div className="container">
      <Navbar />
      {creatingAccount ? (
        <AccountCreation
          email={email}
          setEmail={setEmail}
          password={password}
          setPassword={setPassword}
          confirmPassword={confirmPassword}
          setConfirmPassword={setConfirmPassword}
          alertMessage={alertMessage}
          setAlertMessage={setAlertMessage}
          handleNextStep={handleNextStep}
        />
      ) : (
        <ProfileCreation
          username={username}
          setUsername={setUsername}
          profileAvatar={profileAvatar}
          setProfileAvatar={setProfileAvatar}
          alertMessage={alertMessage}
          setAlertMessage={setAlertMessage}
          handleRegister={handleRegister}
          creatingAccount={creatingAccount}
          setCreatingAccount={setCreatingAccount}
        />
      )}
    </div>
  );
};

export default Register;
