import React, { useContext, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { AppContext } from "../../context/appContext";
import GameSocket from "../components/GameSocket";
import { FetchGames } from "../Helpers/APIFetch";

import "./JoinGame.css";

const JoinGame = () => {
  const user = useSelector((state) => state.user);
  const { gameID, setGameID } = useContext(AppContext);
  const [alertMessage, setAlertMessage] = useState("");
  const navigate = useNavigate();

  const { joiningGame } = GameSocket();

  const handleGameIDChange = (e) => {
    e.preventDefault();
    setGameID(e.target.value.toUpperCase());
  };

  const checkGame = async () => {
    let games = await FetchGames();
    return games.findIndex(game => game.gameID === gameID) !== -1;
  };

  const handleJoiningGame = async () => {
    let checkedGame = await checkGame()
    if (gameID === "") {
      return setAlertMessage("Please enter a Nexus ID");
    } else if (gameID.length !== 5) {
      return setAlertMessage("A Nexus ID will have 5 characters");
    } else if (!checkedGame) {
      return setAlertMessage("Nexus ID does not exist.");
    } else {
      joiningGame(user);
      navigate(`/games/${gameID}`);
    }
  };

  return (
    <div className="join-game-container">
      <form className="form-field">
        <input
          className="form-input-bar"
          required="required"
          type="text"
          placeholder="Enter Game Code"
          value={gameID}
          onChange={handleGameIDChange}
        />
      </form>
      <button className="submit-button" onClick={handleJoiningGame}>
        Join Game
      </button>
      <div className="alert">{alertMessage}</div>
    </div>
  );
};

export default JoinGame;
