export const NexusProFeatures = [
  {
    name: "App Icons",
    description:
      "Gain access to additional app icons and help the nexus app fit right at home with the rest of your apps.",
  },
  {
    name: "Avatars",
    description:
      "Pro subscribers are able to have more profile avatars that they can choose from. With access to new additions as well.",
  },
  {
    name: "Extended History",
    description:
      "Increase the amount of games you can view in your history from 3 to infinity making it so easy to prove how many games you've won!",
  },
  {
    name: "Unlimited Deck Management",
    description:
      "Keep track of an unlimited amount of decks and each of their wins and losses!",
  },
  {
    name: "Themes",
    description:
      "You can theme the app the way you want with additional color options. Change certain buttons, icons and more all in your favorite color.",
  },
  {
    name: "And More!",
    description:
      "As the development of Nexus continues, most enhancements and new features will be available to Nexus Pro subscribers only.",
  },
];
