export const FooterItems = [
    {
        title: "Home",
        url: "/",
        cName: "footer-link",
    },
    {
        title: "Login",
        url: "/login",
        cName: "footer-link",
    },
    {
        title: "Nexus Pro",
        url: "/nexus-pro",
        cName: "footer-link",
    },
    {
        title: "FAQs",
        url: "/FAQ",
        cName: "footer-link",
    },
    {
        title: "Privacy",
        url: "/privacy",
        cName: "footer-link",
    },
    {
        title: "Terms of Use",
        url: "/terms",
        cName: "footer-link",
    },
]