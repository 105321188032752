import React, { useLayoutEffect } from "react";

import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import "./NexusProInfo.css";

const EULA = () => {
    useLayoutEffect(() => {
        window.scrollTo(0, 0);
      });
  return (
    <div className="container">
      <Navbar />
      <div className="pro-container white-text">
        <div className="privacy-policy">
          <h1 className="faq-heading brand-color">
            End-User License Agreement (EULA)
          </h1>

          <p>
            This End-User License Agreement ("Agreement") is a legal agreement
            between you ("User") and Nexus: MTG Companion ("Company") governing
            the use of the web application and iOS application ("App"). By
            accessing or using the App, you agree to be bound by the terms and
            conditions of this Agreement.
          </p>

          <h2>1. Grant of License:</h2>

          <p>
            Subject to the terms and conditions of this Agreement, Company
            grants User a non-exclusive, non-transferable license to use the App
            solely for personal, non-commercial purposes. User shall not sell,
            transmit, host, or commercially exploit the service, copy or modify
            the App, or reverse compile or engineer it.
          </p>

          <h2>2. Availability on Apple App Store:</h2>

          <p>
            The App is made available on the Apple App Store and is subject to
            the terms and conditions set forth by Apple Inc. User acknowledges
            and agrees to comply with Apple's App Store terms and conditions in
            addition to this Agreement.
          </p>

          <h2>3. Regular Updates:</h2>

          <p>
            Company may release regular updates to improve the functionality,
            features, and security of the App. User acknowledges and agrees that
            these updates may be necessary for optimal use of the App and agrees
            to promptly install and use the latest version provided by Company.
          </p>

          <h2>4. Feedback and Suggestions:</h2>

          <p>
            User acknowledges that any feedback or suggestions provided to
            Company regarding the App may be used by Company without any
            obligation to compensate User. Company shall have the right to
            incorporate such feedback or suggestions into its products or
            services without any restrictions.
          </p>

          <h2>5. Use of Data:</h2>

          <p>
            User acknowledges and agrees that Company may collect and use data
            for the purpose of user authentication and providing in-app content.
            Company shall handle User's data in accordance with its Privacy
            Policy, which is incorporated by reference into this Agreement.
          </p>

          <h2>6. Contact:</h2>

          <p>
            For any inquiries or support related to the App, User may contact
            Company via email at
            <a href="mailto:support@playnexus.app">support@playnexus.app</a>.
            Company will make reasonable efforts to respond to User's inquiries
            within a reasonable timeframe.
          </p>

          <h2>7. Termination:</h2>

          <p>
            This Agreement is effective until terminated by either party. User
            may terminate this Agreement by ceasing all use of the App. Company
            may terminate this Agreement at any time without prior notice if
            User fails to comply with any terms or conditions of this Agreement.
            Upon termination, User must cease all use of the App and delete any
            copies in their possession.
          </p>

          <h2>8. Limitations of Liability:</h2>

          <p>
            TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT SHALL
            COMPANY OR ITS EMPLOYEES, AGENTS, OR AFFILIATES BE LIABLE FOR ANY
            INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL, OR PUNITIVE DAMAGES
            ARISING OUT OF OR IN CONNECTION WITH THE USE OF THE APP.
          </p>
          <h2>9. No Warranty:</h2>

          <p>
            THE APP IS PROVIDED "AS IS" AND "AS AVAILABLE" WITHOUT WARRANTY OF
            ANY KIND. TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, COMPANY
            DISCLAIMS ALL WARRANTIES, EXPRESS OR IMPLIED, INCLUDING BUT NOT
            LIMITED TO, WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR
            PURPOSE, AND NON-INFRINGEMENT. COMPANY DOES NOT WARRANT THAT THE APP
            WILL BE ERROR-FREE, UNINTERRUPTED, OR FREE OF HARMFUL COMPONENTS.
            COMPANY MAKES NO REPRESENTATIONS OR WARRANTIES ABOUT THE ACCURACY,
            COMPLETENESS, OR RELIABILITY OF ANY CONTENT, INFORMATION, OR
            MATERIALS PROVIDED THROUGH THE APP. USER ACKNOWLEDGES AND AGREES
            THAT ANY USE OF THE APP IS AT THEIR SOLE RISK, AND THEY WILL BE
            SOLELY RESPONSIBLE FOR ANY DAMAGE TO THEIR DEVICE OR LOSS OF DATA
            THAT RESULTS FROM THE USE OF THE APP. SOME JURISDICTIONS DO NOT
            ALLOW THE EXCLUSION OF CERTAIN WARRANTIES, SO THE ABOVE LIMITATIONS
            OR EXCLUSIONS MAY NOT APPLY TO EVERY USER.
          </p>

          <h2>10. Governing Law:</h2>

          <p>
            This Agreement shall be governed by and construed in accordance with
            the laws of New York. Any disputes arising out of or relating to
            this Agreement shall be subject to the exclusive jurisdiction of the
            courts located in New York.
          </p>

          <p>
            By accessing or using the App, User acknowledges that they have
            read, understood, and agreed to be bound by this EULA. If User does
            not agree with any provision of this Agreement, they should not use
            the App.
          </p>

          <p>
            This Agreement constitutes the entire agreement between User and
            Company regarding the App and supersedes all prior agreements and
            understandings.
          </p>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default EULA;
