import React, { useState } from "react";
import Counter from "../Helpers/Counter";

import "./PlayerCard.css";

const PlayerCard = (props) => {
  const [counterText, setCounterText] = useState("HP");
  const [viewHealth, setViewHealth] = useState(true);
  const [viewPoison, setViewPoison] = useState(false);
  const [viewCommanderDMG, setViewCommanderDMG] = useState(false);

  const handleViewHealthClick = () => {
    setViewHealth(true);
    setViewPoison(false);
    setViewCommanderDMG(false);
    setCounterText("HP");
  };

  const handleViewPoisonClick = () => {
    setViewHealth(false);
    setViewPoison(true);
    setViewCommanderDMG(false);
    setCounterText("Poison");
  };

  const handleViewCommanderDMGClick = () => {
    setViewHealth(false);
    setViewPoison(false);
    setViewCommanderDMG(true);
    setCounterText("Cmd Dmg");
  };

  return (
    <div className="player-card">
      {/* <div className="player-row"> */}
      <div className="player-col">
        <div className="player-username">{props.username}</div>
        <div className="player-row">
          <div className="">
            <img
              className="player-pfp"
              src={props.profileAvatar.source}
              alt="user's profile"
            />
            <div className="player-health">
              {" "}
              <Counter
                counterText={counterText}
                health={props.health}
                poisonCount={props.poisonCount}
                commanderDamage={props.commanderDamage}
              />
            </div>
            <div className="player-text">{counterText}</div>
          </div>
          <ul className="player-counter-buttons">
            <li>
              <i
                className="white-text fa-solid fa-heart player-counter-button-picker-icon"
                style={{ color: viewHealth ? "blue" : "white" }}
                onClick={handleViewHealthClick}
              ></i>
            </li>
            <li>
              <i
                className="white-text fa-solid fa-skull-crossbones player-counter-button-picker-icon"
                style={{ color: viewPoison ? "blue" : "white" }}
                onClick={handleViewPoisonClick}
              ></i>
            </li>
            {/* <li>
              <i
                className="white-text fa-solid fa-shield-halved  player-counter-button-picker-icon"
                style={{ color: viewCommanderDMG ? "blue" : "white" }}
                onClick={handleViewCommanderDMGClick}
              ></i>
            </li> */}
          </ul>
        </div>
      </div>

      {/* </div> */}
    </div>
  );
};

export default PlayerCard;
