import React from "react";
import { Link } from "react-router-dom";

import "./AuthForm.css";

const AccountCreation = (props) => {
  const handleRegister = async (e) => {
    e.preventDefault();
    if(!/^\S+@\S+\.\S+$/.test(props.email)) {
      props.setAlertMessage("Please use a valid email address.");
    }
    // if (!props.email.includes("@")) {
    //   props.setAlertMessage("Please use a valid email address.");
    // }
     else if (props.password.length < 8) {
      props.setAlertMessage(
        "Your password must be at least 8 characters long."
      );
    } else if (!/\d/.test(props.password)) {
      props.setAlertMessage(
        "Your password must contain at least 1 numeric character."
      );
    } else {
      props.handleNextStep();
      props.setAlertMessage("");
    }
  };

  return (
    <div className="container">
      <div className="form-container">
        <form className="form-field" onSubmit={handleRegister}>
          <div>
            <label className="white-text form-input-label">Email</label>
            <input
              className="form-input-bar"
              //   required
              type="email"
              placeholder="Enter Email"
              value={props.email}
              onChange={(e) => {
                props.setEmail(e.target.value);
              }}
            />
          </div>
          <div>
            <label className="white-text form-input-label">Password</label>
            <input
              className="form-input-bar"
              //   required
              type="password"
              placeholder="Password"
              value={props.password}
              onChange={(e) => {
                props.setPassword(e.target.value);
              }}
            />
          </div>
          <div>
            <label className="white-text form-input-label">Confirm Password</label>
            <input
              className="form-input-bar"
              //   required
              type="password"
              placeholder="Password"
              value={props.confirmPassword}
              onChange={(e) => {
                props.setConfirmPassword(e.target.value);
              }}
            />
          </div>
          <button className="submit-button">Register</button>
        </form>
      </div>
      <div className="alert">{props.alertMessage}</div>
      <div className="white-text">Already have an account? <Link to='/login'><span>Login Here</span></Link>.</div>
    </div>
  );
};

export default AccountCreation;
