export const FAQList = [
  {
    title: "What are the requirements?",
    description:
      "Nexus on the web will work with any modern web browser on desktop or mobile. The Nexus iOS app requires iOS 16 or higher.",
  },
  {
    title: "How many players are supported?",
    description:
      "Technically it supports as many players as you want but the screen will get a bit crowded so the recommended ammount is up to 8 players. But as you know, 8 player commander games can take a LOOOOOOONG time.",
  },
  {
    title: "How much does it cost?",
    description:
      "The goal is to keep the core functionality of Nexus is 100% free forever. If you want to support me and the development of the app, there is an option to subscribe to Nexus Pro. Nexus Pro will unlock additional features such as themes, app icons, extended game history and more to come.",
  },
  {
    title: "Why do I have to create an account?",
    description:
      "Creating an account allows you to have a profile that let's other players know who you are when playing with them.",
  },
  {
    title: "is Nexus only for Magic: The Gathering?",
    description:
      "Nexus was built and designed for Magic: The gathering but there is nothing stopping you for using it as a counter for any other game.",
  },
  {
    title: "Do you collect or sell my data?",
    description:
      "Privacy is a big deal and only your email and username are collected for signing in and knowing who you are in game. It isn't sold or shared to anyone and once your delete your account it's deleted from our server for good.",
  },
  {
    title: "Help! I need support!!!",
    description:
      "You can email support@playnexus.app for help. Please be patient though, it's just me!",
  },
];
