import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const api = createApi({
  reducerPath: "app",
  baseQuery: fetchBaseQuery({
    // baseUrl: process.env.REACT_APP_SERVER_URL,
    baseUrl: "https://nexus-mtg-production.up.railway.app",
    // baseUrl: "http://localhost:3000",
    
  }),

  //User Endpoints
  endpoints: (builder) => ({
    createUser: builder.mutation({
      query: (user) => ({
        url: "/users",
        method: "POST",
        body: user,
      }),
    }),

    loginUser: builder.mutation({
      query: (user) => ({
        url: "/users/login",
        method: "POST",
        body: user,
      }),
    }),

    logoutUser: builder.mutation({
      query: (payload) => ({
        url: "/users/logout",
        method: "DELETE",
        body: payload,
      }),
    }),

    deleteAccount: builder.mutation({
      query: (payload) => ({
        url: `/users/delete/${payload.username}`,
        method: "DELETE",
        body: payload
      }),
    }),

    //Reset Password Endpoints
    forgotPassword: builder.mutation({
      query: (email) => ({
        url: "/forgot-password",
        method: "POST",
        body: email,
      }),
    }),

    resetPassword: builder.mutation({
      query: (payload) => ({
        url: `/reset-password/${payload.id}/${payload.token}`,
        method: "POST",
        body: payload,
      }),
    }),

    //User Update Endpoints
    updateUserAvatar: builder.mutation({
      query: (user) => ({
        url: "/users/update/avatar",
        method: "POST",
        body: user,
      }),
    }),

    updateUserEmail: builder.mutation({
      query: (user) => ({
        url: "/users/update/email",
        method: "POST",
        body: user,
      }),
    }),

    updateUserPassword: builder.mutation({
      query: (user) => ({
        url: "/users/update/password",
        method: "POST",
        body: user,
      }),
    }),

    updateUserUsername: builder.mutation({
      query: (user) => ({
        url: "/users/update/username",
        method: "POST",
        body: user,
      }),
    }),

    addGame:builder.mutation({
      query: (user) => ({
        url: "users/add/game",
        method: "POST",
        body: user,
      }),
    }),

    addFriend:builder.mutation({
      query: (user) => ({
        url: "users/friend/add",
        method: "POST",
        body: user,
      }),
    }),

    removeFriend:builder.mutation({
      query: (user) => ({
        url: "users/friend/remove",
        method: "POST",
        body: user,
      }),
    }),

    //Game Endpoints
    createGame: builder.mutation({
      query: (game) => ({
        url: "/games",
        method: "POST",
        body: game,
      }),
    }),

    joinGame: builder.mutation({
      query: (game) => ({
        url: `/games/${game.gameID}`,
        method: "POST",
        body: game,
      }),
    }),

    leaveGame: builder.mutation({
      query: (game) => ({
        url: `/games/${game.gameID}/leave`,
        method: "POST",
        body: game,
      }),
    }),

    endGame: builder.mutation({
      query: (game) => ({
        url: `/games/${game.gameID}/end`,
        method: "DELETE",
        body: game,
      }),
    }),

    //Player endpoints
    updatePlayer: builder.mutation({
      query: (game) => ({
        url: `/games/${game.gameID}/update`,
        method: "POST",
        body: game,
      }),
    }),
  }),
});

export const {
  useCreateUserMutation,
  useLoginUserMutation,
  useLogoutUserMutation,
  useDeleteAccountMutation,

  useForgotPasswordMutation,
  useResetPasswordMutation,

  useUpdateUserAvatarMutation,
  useUpdateUserEmailMutation,
  useUpdateUserPasswordMutation,
  useUpdateUserUsernameMutation,

  useCreateGameMutation,
  useJoinGameMutation,
  useLeaveGameMutation,
  useUpdatePlayerMutation,
  useEndGameMutation,

  useAddFriendMutation,
  useAddGameMutation,
  useRemoveFriendMutation,
} = api;

export default api;
