export const CodeFilter = [
    '2g1c',
   'anal',
    'anus',
    'ass',
    'bang',
    'bbw',
    'bdsm',
    'bimbo',
    'bitch',
    'boner',
    'boob',
    'boobs',
    'booty',
    'busty',
    'butt',
    'clit',
    'cock',
    'cocks',
    'coon',
    'coons',
    'cum',
    'cunt',
    'dick',
    'dildo',
    'domme',
    'dvda',
    'ecchi',
    'fag',
    'fecal',
    'felch',
    'fuck',
    'guro',
    'horny',
    'jizz',
    'juggs',
    'kike',
    'kinky',
    'milf',
    'mong',
    'negro',
    'nigga',
    'nigge',
    'niggr',
    'niger',
    'nig',
    'nsfw',
    'nude',
    'nymph',
    'orgy',
    'paedo',
    'paki',
    'pedo',
    'penis',
    'pikey',
    'piss',
    'poon',
    'porn',
    'porno',
    'pthc',
    'pubes',
    'pussy',
    'queaf',
    'queef',
    'quim',
    'rape',
    'scat',
    'semen',
    'sex',
    'sexo',
    'sexy',
    'shit',
    'shota',
    'skeet',
    'slut',
    'smut',
    'snatch',
    'sodom',
    'spic',
    'spunk',
    'suck',
    'sucks',
    'tit',
    'tits',
    'tittie',
    'titty',
    'tosser',
    'tranny',
    'tushy',
    'twat',
    'twink',
    'twinky',
    'vag',
    'vulva',
    'wank',
    'whore',
    'xx',
    'xxx',
    'yaoi',
    'yiffy'
]