import React from "react";

import '../pages/FAQs.css'


const FAQ = (props) => {
    return (
        <div>
            <h2 className="faq-title brand-color">{props.title}</h2>
            <p className="faq-description white-text">{props.description}</p>
        </div>
    )
};

export default FAQ;