import React from "react";

const ProFeature = (props) => {
  return (
    <div>
      <h2 className="faq-title brand-color"> {props.name} </h2>
      <p> {props.description} </p>
    </div>
  );
};

export default ProFeature;
