import React from "react";

const Avatar = ({ avatar, handleToggle, activeAvatar }) => {
  return (
    <div className="">
      <img
        className={activeAvatar ? "avatar-grid-item " : "avatar-grid-item selected"}
        src={avatar.source}
        alt="avatar icon"
        onClick={() => {
          handleToggle(avatar);
          console.log(avatar)
        }}
      />
    </div>
  );
};

export default Avatar;
