export class Game {
  constructor(_id, gameID, allPlayers, players, startingHealth, createdAt, updatedAt, __v) {
    this._id = _id;
    this.gameID = gameID;
    this.allPlayers = allPlayers;
    this.players = players;
    this.startingHealth = startingHealth;
    this.createdAt = createdAt;
    this.updatedAt = updatedAt;
    this.__v = __v
  }
}
