import React, { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { AppContext } from "../../context/appContext";
import { useSelector } from "react-redux";
import GameSocket from "../components/GameSocket";

import { Player } from "../Models/PlayerModel";

import "./GameSetup.css";

const GameSetup = ({ handleCancelGame }) => {
  const user = useSelector((state) => state.user);

  const { gameID, setPlayer } = useContext(AppContext);
  const navigate = useNavigate();

  const { creatingGame } = GameSocket();

  const [health, setHealth] = useState(null);
  const [alertMessage, setAlertMessage] = useState("");
  const healthOptions = [20, 30, 40, 100];

  const handleCreateGame = () => {
    if (health !== null) {
      const player = new Player(user.username, user.profileAvatar, health, 0, 0);
      creatingGame(player, health);
      setPlayer(player);
      navigate(`/games/${gameID}`);
    } else {
      setAlertMessage("Please select a starting life.");
    }
  };

  return (
    <div className="container">
      <div className="lobby-code creating">Creating New Game</div>
      <div className="lobby-code creating">{`Nexus ID: ${gameID}`}</div>

      <div className="health-options-container">
        <div className="life-text">Set Starting Life:</div>
        <div className="health-options">
          {healthOptions.map((healthOption) => (
            <div className="health-option-container" key={healthOption}>
              <button
                className="health-option-button"
                onClick={() => {
                  setHealth(healthOption);
                }}
                style={{
                  backgroundColor: health === healthOption ? "#b9ffb7" : "#fff",
                }}
              >
                {healthOption}
              </button>
            </div>
          ))}
        </div>
        <div>{alertMessage}</div>
        <div className="game-setup-list">
          <ul>
            <li>
              <button className="brand-button-main" onClick={handleCreateGame}>
                Create Game
              </button>
            </li>
            <li>
              <button
                className="brand-button-main"
                onClick={() => handleCancelGame()}
              >
                Cancel
              </button>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default GameSetup;
