import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import words from "profane-words";

import AccountNav from "../components/AccountNav";
import Avatar from "../../site/pages/auth/Avatar";
import { avatars } from "../../assets/Avatars/Avatars";
import { proAvatars } from "../../assets/Avatars/Avatars";
import {
  useUpdateUserAvatarMutation,
  useUpdateUserEmailMutation,
  useUpdateUserPasswordMutation,
  useUpdateUserUsernameMutation,
  useDeleteAccountMutation,
} from "../../api/api";
import { userSlice } from "../../api/userSlice";
import store from "../../api/store";
import DangerModal from "../components/DangerModal";

const AccountSettings = () => {
  const user = useSelector((state) => state.user);
  const navigate = useNavigate();

  const [updateAvatar] = useUpdateUserAvatarMutation();
  const [updateEmail] = useUpdateUserEmailMutation();
  const [updatePassword] = useUpdateUserPasswordMutation();
  const [updateUsername] = useUpdateUserUsernameMutation();
  const [deleteAccount] = useDeleteAccountMutation();

  const [activeAvatar, setActiveAvatar] = useState();
  const [avatarAlertMessage, setAvatarAlertMessage] = useState("");
  const [emailAlertMessage, setEmailAlertMessage] = useState("");
  const [passwordAlertMessage, setPasswordAlertMessage] = useState("");
  const [usernameAlertMessage, setUsernameAlertMessage] = useState("");

  const [newAvatar, setNewAvatar] = useState();

  const [currentEmail, setCurrentEmail] = useState("");
  const [newEmail, setNewEmail] = useState("");
  const [confirmNewEmail, setConfirmNewEmail] = useState("");

  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const [newUsername, setNewUsername] = useState("");

  const [deleteVerifyEmail, setDeleteVerifyEmail] = useState("");
  const [deleteVerifyPassword, setDeleteVerifyPassword] = useState("");
  const [modalAlertMessage, setModalAlertMessage] = useState("");
  const [showingDangerModal, setShowingDangerModal] = useState(false);

  useEffect(() => {
    console.log(proAvatars);
  });

  const toggleModal = () => {
    window.scrollTo(0, 0);
    setShowingDangerModal(!showingDangerModal);
  };

  const handleToggle = (avatar) => {
    activeAvatar === avatar ? setActiveAvatar() : setActiveAvatar(avatar);
  };

  const handleToggleAvatar = (avatar) => {
    if (newAvatar === avatar) {
      setNewAvatar();
    } else {
      setNewAvatar(avatar);
    }
  };

  const handleUpdateAvatar = () => {
    if (!newAvatar) {
      setAvatarAlertMessage("Please select a new Avatar.");
    } else {
      updateAvatar({ user, newAvatar });
      store.dispatch(userSlice.actions.setAvatar(newAvatar));
      setAvatarAlertMessage("");
      setNewAvatar();
    }
  };

  const handleUpdateEmail = (e) => {
    e.preventDefault();
    if (!newEmail.includes("@")) {
      return setEmailAlertMessage("Please use a valid email address.");
    } else if (newEmail !== confirmNewEmail) {
      return setEmailAlertMessage("New email does not match.");
    } else if (user.email !== currentEmail) {
      return setEmailAlertMessage("Current Email is incorrect.");
    }
    updateEmail({ user, newEmail });
    store.dispatch(userSlice.actions.setEmail(newEmail));
    setEmailAlertMessage("");
    setNewEmail("");
    setCurrentEmail("");
    setConfirmNewEmail("");
  };

  const handleUpdatePassword = (e) => {
    e.preventDefault();
    if (newPassword === confirmPassword) {
      updatePassword({ user, currentPassword, newPassword }).then(
        ({ data }) => {
          if (data) {
            console.log(data);
          } else {
            setPasswordAlertMessage("Incorrect current password.");
          }
        }
      );
      setCurrentPassword("");
      setNewPassword("");
      setConfirmPassword("");
    } else {
      setPasswordAlertMessage("Passwords does not match");
    }
  };

  const handleUpdateUsername = (e) => {
    e.preventDefault();
    setUsernameAlertMessage("");
    for (let i = 0; i < words.length; i++) {
      if (newUsername.includes(words[i])) {
        return setUsernameAlertMessage(
          "Please pick a more appropriate username."
        );
      } else if (!/^[a-zA-Z0-9]+$/.test(newUsername)) {
        return setUsernameAlertMessage(
          "Username can only contain alphanumeric characters."
        );
      } else if (!newUsername) {
        return setUsernameAlertMessage("");
      }
    }
    updateUsername({ user, newUsername }).then(({ data }) => {
      if (data) {
        console.log(data);
      }
    });
    store.dispatch(userSlice.actions.setUsername(newUsername));
    setUsernameAlertMessage("");
    setNewUsername("");
  };

  const handleDeleteAccount = (e) => {
    const username = user.username;
    const email = deleteVerifyEmail;
    const password = deleteVerifyPassword;
    e.preventDefault();
    deleteAccount({ username, email, password }).then(({ data }) => {
      if (data) {
        console.log(data);
        setShowingDangerModal(false);
      } else {
        setModalAlertMessage("Incorrect email or password.");
      }
      navigate("/");
    });
  };

  return (
    <div className="container">
      <AccountNav />
      <div className="settings-container">
        <h2>Account Settings</h2>
        <img
          className="profile-pic"
          src={user.profileAvatar.source}
          alt="profile avatar"
        />
        <h3 className="username">{user.username}</h3>
        Update Avatar
        <div className="avatar-grid">
          {avatars.map((avatar) => (
            <div
              onClick={() => {
                handleToggleAvatar(avatar);
              }}
            >
              <Avatar
                key={avatar.name}
                avatar={avatar}
                handleToggle={handleToggle}
                activeAvatar={activeAvatar === avatar}
              />
            </div>
          ))}
        </div>
        <button
          className="brand-button-main"
          type="submit"
          onClick={handleUpdateAvatar}
        >
          Update
        </button>
        <div className="alert">{avatarAlertMessage}</div>
        <form className="form-field" onSubmit={handleUpdateUsername}>
          <label className="white-text form-input-label">Update Username</label>
          <div className="row">
            <input
              className="form-input-bar"
              required
              type="text"
              placeholder="Enter Username"
              value={newUsername}
              onChange={(e) => {
                setNewUsername(e.target.value);
              }}
            />
            <button className="brand-button-main" type="submit">
              Update
            </button>
          </div>
          <div className="alert">{usernameAlertMessage}</div>
        </form>
        <form className="form-field" onSubmit={handleUpdateEmail}>
          <label className="white-text form-input-label">Update Email</label>
          <div className="text-field">
            <input
              className="form-input-bar"
              required
              type="text"
              placeholder="Enter Current Email"
              value={currentEmail}
              onChange={(e) => {
                setCurrentEmail(e.target.value);
              }}
            />
          </div>
          <div className="text-field">
            <input
              className="form-input-bar"
              required
              type="text"
              placeholder="Enter New Email"
              value={newEmail}
              onChange={(e) => {
                setNewEmail(e.target.value);
              }}
            />
          </div>
          <div className="row">
            <input
              className="form-input-bar"
              required
              type="text"
              placeholder="Confirm New Email"
              value={confirmNewEmail}
              onChange={(e) => {
                setConfirmNewEmail(e.target.value);
              }}
            />
            <button className="brand-button-main">Update</button>
          </div>
          <div className="alert">{emailAlertMessage}</div>
        </form>
        <form className="form-field" onSubmit={handleUpdatePassword}>
          <label className="white-text form-input-label">Update Password</label>
          <div className="text-field">
            <input
              className="form-input-bar"
              required
              type="password"
              placeholder="Enter Current Password"
              value={currentPassword}
              onChange={(e) => {
                setCurrentPassword(e.target.value);
              }}
            />
          </div>

          <div className="text-field">
            <input
              className="form-input-bar"
              required
              type="password"
              placeholder="Enter New Password"
              value={newPassword}
              onChange={(e) => {
                setNewPassword(e.target.value);
              }}
            />
          </div>
          <div className="row">
            <input
              className="form-input-bar"
              required
              type="password"
              placeholder="Confirm New Password"
              value={confirmPassword}
              onChange={(e) => {
                setConfirmPassword(e.target.value);
              }}
            />
            <button className="brand-button-main">Update</button>
          </div>
          <div className="alert">{passwordAlertMessage}</div>
        </form>
        <button className="brand-button-main delete" onClick={toggleModal}>
          Danger Zone!
        </button>
        {showingDangerModal ? (
          <DangerModal
            action={handleDeleteAccount}
            toggle={toggleModal}
            setDeleteVerifyEmail={setDeleteVerifyEmail}
            deleteVerifyEmail={deleteVerifyEmail}
            setDeleteVerifyPassword={setDeleteVerifyPassword}
            deleteVerifyPassword={deleteVerifyPassword}
            modalAlertMessage={modalAlertMessage}
          />
        ) : null}
      </div>
    </div>
  );
};

export default AccountSettings;
