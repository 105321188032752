import React, { useLayoutEffect } from "react";
import {Helmet} from "react-helmet"

import "./Home.css";

import features from "../components/Features";
import Navbar from "../components/Navbar";
import Feature from "../components/Feature";
import Footer from "../components/Footer";
import { Link } from "react-router-dom";
import NexusGameExample from "../../assets/mockups/NexusGameExample.png";
import { ReactComponent as AppStoreLink } from "../../assets/AppStoreWhite.svg";

const Home = () => {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  });

  return (
    <div className="container">
      <Helmet>
      <meta name="apple-itunes-app" content="app-id=6449751981" />
      </Helmet>
      <div className="home-container">
        <Navbar />
        <div className="home-hero">
          <div className="home-highlight bold-text white-text">
            In person Magic.{" "}
            <span className="bold-text brand-color">Connected.</span>
            {/* <p style={{ fontSize: 12 }}>
              {" "}
              Welcome! Nexus is currently in a closed iOS beta and an open web
              beta and is expected to be released Summer 2023.
            </p> */}
          </div>

          <div>
            <img
              className="hero-image"
              src={NexusGameExample}
              alt="Nexus Game Room"
            ></img>
          </div>
          <div>
            <a href="https://apps.apple.com/us/app/nexus-mtg-companion/id6449751981" target="_blank" rel="noreferrer">
              <AppStoreLink className="app-store-link" />
            </a>
          </div>
        </div>
      </div>
      <div className="home-row">
        <div className="home-callout">
          Nexus is the first app designed to connect{" "}
          <span className="bold-text black-text">Magic The Gathering</span>{" "}
          players <span className="bold-text black-text">in person</span> making
          it easier than ever to keep track and manage your game.
        </div>
      </div>

      <div className="highlight-row">
        <div className="header">Features</div>
        <div className="feature-list">
          {features.map((feature) => (
            <Feature
              key={feature.id}
              id={feature.id}
              feature={feature.feature}
              description={feature.description}
              image={feature.image}
              className={feature.className}
            />
          ))}
        </div>
      </div>
      <div className="home-more-container white-text">
        <div className="home-more">
          <i className="fa-solid fa-star fa-6x brand-color"></i>
          <h2>Discover Nexus Pro!</h2>
          <div>
            Unlock even more with <Link to="/nexus-pro">Nexus Pro</Link>{" "}
            features.
          </div>
        </div>
        <div className="home-more">
          <i className="fa-solid fa-circle-question fa-6x brand-color"></i>
          <h2>Have questions?</h2>
          <div>
            Visit the <Link to="/FAQ">FAQ</Link> page.
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default Home;
