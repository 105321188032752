import React, { useEffect, useState, useContext } from "react";
import Counter from "../Helpers/Counter";
import { AppContext } from "../../context/appContext";
import "./UserCard.css";
import GameSocket from "./GameSocket";

const UserCard = (props) => {
  const [health, setHealth] = useState(props.health);
  const [commanderDamage, setCommanderDamage] = useState(props.commanderDamage);
  const [poisonCount, setPoisonCount] = useState(props.poisonCount);
  const [counterText, setCounterText] = useState("HP");
  const [viewHealth, setViewHealth] = useState(true);
  const [viewPoison, setViewPoison] = useState(false);
  const [viewCommanderDMG, setViewCommanderDMG] = useState(false);

  const { player } = useContext(AppContext);

  const { updatingPlayer } = GameSocket();

  const handleViewHealthClick = () => {
    setViewHealth(true);
    setViewPoison(false);
    setViewCommanderDMG(false);
    setCounterText("HP");
  };

  const handleViewPoisonClick = () => {
    setViewHealth(false);
    setViewPoison(true);
    setViewCommanderDMG(false);
    setCounterText("Poison");
  };

  const handleViewCommanderDMGClick = () => {
    setViewHealth(false);
    setViewPoison(false);
    setViewCommanderDMG(true);
    setCounterText("Commander Damage");
  };

  const handleSubtractButtonClick = async () => {
    switch (counterText) {
      case "HP":
        if (health > 0) {
          const updatedHealth = health - 1;
          await updatingPlayer(player, "health", updatedHealth);
        }
        break;
      case "Poison":
        if (poisonCount > 0) {
          const updatedPoison = poisonCount - 1;
          await updatingPlayer(player, "poisonCount", updatedPoison);
        }
        break;
      case "Commander Damage":
        if (commanderDamage > 0) {
          const updatedCommanderDamage = commanderDamage - 1;
          await updatingPlayer(
            player,
            "commanderDamage",
            updatedCommanderDamage
          );
        }
        break;
      default:
        break;
    }
  };

  const handleAddButtonClick = async () => {
    switch (counterText) {
      case "HP":
        const updatedHealth = health + 1;
        await updatingPlayer(player, "health", updatedHealth);
        break;
      case "Poison":
        const updatedPoison = poisonCount + 1;
        await updatingPlayer(player, "poisonCount", updatedPoison);
        break;
      case "Commander Damage":
        const updatedCommanderDamage = commanderDamage + 1;
        await updatingPlayer(player, "commanderDamage", updatedCommanderDamage);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    setHealth(props.health);
    setPoisonCount(props.poisonCount);
    setCommanderDamage(props.commanderDamage);
  }, [player, props.commanderDamage, props.health, props.poisonCount]);

  return (
    <div className="user-card">
      <div className="username-card">{props.username}</div>
      <img className="user-pfp" src={props.profileAvatar} alt="user's profile" />
      <div className="counter-row">
        <button
          className="health-counter-button"
          onClick={handleSubtractButtonClick}
        >
          -
        </button>
        <div className="user-health">
          <Counter
            counterText={counterText}
            health={props.health}
            poisonCount={props.poisonCount}
            commanderDamage={props.commanderDamage}
          />
        </div>
        <button
          className="health-counter-button"
          onClick={handleAddButtonClick}
        >
          +
        </button>
      </div>
      <ul className="row counter-buttons">
        <li>
          <i
            className="white-text fa-solid fa-heart fa-xl counter-button-picker-icon"
            style={{ color: viewHealth ? "blue" : "white" }}
            onClick={handleViewHealthClick}
          ></i>
        </li>
        <li>
          <i
            className="white-text fa-solid fa-skull-crossbones fa-xl counter-button-picker-icon"
            style={{ color: viewPoison ? "blue" : "white" }}
            onClick={handleViewPoisonClick}
          ></i>
        </li>
        {/* <li>
          <i
            className="white-text fa-solid fa-shield-halved fa-xl counter-button-picker-icon"
            style={{ color: viewCommanderDMG ? "blue" : "white" }}
            onClick={handleViewCommanderDMGClick}
          ></i>
        </li> */}
      </ul>
      <div>{counterText}</div>
    </div>
  );
};

export default UserCard;
