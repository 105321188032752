import React, { useLayoutEffect } from "react";

import FAQ from "../components/FAQ";
import Navbar from "../components/Navbar";
import { FAQList } from "../../assets/FAQList";
import Footer from "../components/Footer";
import "./FAQs.css";

const FAQs = () => {
  
  useLayoutEffect(() => {
    window.scrollTo(0, 0)
});

  return (
    <div className="container">
      <Navbar />
          <div className="faq-container">
          
        <h1 className="faq-heading white-text">Frequently Asked Questions</h1>
        <div className="faq-list">
          {FAQList.map((f) => (
            <FAQ title={f.title} description={f.description} />
          ))}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default FAQs;
