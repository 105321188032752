import React, { useState } from "react";
import { Link } from "react-router-dom";

import Navbar from "../../components/Navbar";
import { useForgotPasswordMutation } from "../../../api/api";

const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const [alertMessage, setAlertMessage] = useState("");

  const [forgotPassword] = useForgotPasswordMutation();

  const handleForgotPassword = (e) => {
    e.preventDefault();
    if (email !== "") {
      forgotPassword({ email }).then(({ data }) => {
        if (data) {
          console.log(data);
          return setAlertMessage(data.message);
        } else {
          return setAlertMessage("Email not registered.");
        }
      });
    } else {
      return setAlertMessage("Please enter an email address.");
    }
  };

  return (
    <div className="container">
      <Navbar />
      <div className="form-container">
        <form className="form-field" onSubmit={handleForgotPassword}>
          <div>
            <label className="white-text form-input-label">Email</label>
            <input
              className="form-input-bar"
              required
              type="text"
              placeholder="Enter Email"
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
              }}
            />
          </div>

          <button className="submit-button">Reset Password</button>
        </form>
      </div>
      <div className="alert">{alertMessage}</div>
      <div className="white-text">
        Don't have an account?{" "}
        <Link to="/register">
          <span>Register Here</span>
        </Link>
        .
      </div>
    </div>
  );
};

export default ForgotPassword;
