import React from "react";
import { Link } from "react-router-dom";

import { FooterItems } from "../../assets/FooterItems";
import "./Footer.css";

const Footer = () => {
  return (
    <div className="footer-container">
      <ul className="footer-links">
        {FooterItems.map((item, index) => (
          <li className={item.cName} key={index}>
            <Link to={item.url}>{item.title}</Link>
          </li>
        ))}
      </ul>
      <div className="footer-copyright">
        <p>© Nexus: MTG Companion</p>
        <p>© Frostforge Studio</p>
      </div>
    </div>
  );
};

export default Footer;
