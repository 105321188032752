import React, { useLayoutEffect } from "react";

import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import { NexusProFeatures } from "../../assets/NexusProFeatures";
import "./NexusProInfo.css";
import ProFeature from "../components/ProFeature";

const NexusProInfo = () => {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  });

  return (
    <div className="container">
      <Navbar />
      <div className="pro-container white-text">
        <h1 className="pro-heading white-text">Nexus Pro</h1>
        <p>
          Nexus is a free app that will always have its core functionality of
          being a real-time connected Magic: The Gathering counter app. Nexus
          Pro is a paid subscription option at a cost of $0.99 USD per month or
          $9.99 USD per year. Nexus Pro gives users the opportunity to support
          me, the sole developer of Nexus, and any future development of the
          app.
        </p>
        <p>
          Nexus Pro is currently only available on iOS. Not all features will
          carry over or be accessible when using the web app.
        </p>
        <br />
        <p>Below are the features you'll gain access to when you subscribe.</p>
        <div className="pro-feature-list">
          {NexusProFeatures.map((feature) => (
            <ProFeature name={feature.name} description={feature.description} />
          ))}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default NexusProInfo;
